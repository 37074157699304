import React, {useState, useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {logoutUser} from "../services/authService";

const AuthContext = React.createContext({
    isAuthenticated: false,
    user: null,
    login: user => {
    },
    logout: () => {
    }
});

export const AuthProvider = ({children}) => {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        const savedAuth = localStorage.getItem('isAuthenticated');
        return savedAuth ? JSON.parse(savedAuth) : false;
    });
    const [user, setUser] = useState(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : null;
    });
    const navigate = useNavigate();

    useEffect(() => {
        if (isAuthenticated) {
            localStorage.setItem('isAuthenticated', 'true');
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
        }
    }, [isAuthenticated, user]);

    const login = userInfo => {
        setIsAuthenticated(true);
        setUser(userInfo);

        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('user', JSON.stringify(userInfo));
    }

    const logout = async () => {
        try {
            await logoutUser();
            setIsAuthenticated(false);
            setUser(null);

            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('user');
        } catch (error) {
            navigate('/500');
        }
    };

    return (<AuthContext.Provider value={{isAuthenticated, user, login, logout}}>
        {children}
    </AuthContext.Provider>);
};

export default AuthContext;