import React from 'react';
import Migration from '../Migration/Migration';
import CSVDownload from '../CSVDownload/CSVDownload';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import AppHeader from "../AppHeader/AppHeader";

const defaultTheme = createTheme();

const Dashboard = () => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ThemeProvider theme={defaultTheme}>

            <AppHeader />

            <Container component="main" maxWidth="lg">

                <CssBaseline/>

                <Box
                    sx={{
                        paddingY: 8,
                        height: isMobile ? 'auto' : 'calc(100vh - 76px)',
                        display: 'flex',
                        flexDirection: isTablet ? 'column' : 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        rowGap: '2rem'
                    }}
                >

                    <Box sx={{ flex: isTablet ? 'auto' : '1 0 50%', maxWidth: isTablet ? '100%' : '50%' }}>
                        <Typography variant="h5">Migrate Engagements</Typography>
                        <Migration/>
                    </Box>

                    <Divider orientation={isTablet ? 'horizontal' : 'vertical'} variant="middle" flexItem sx={{ mx: 4 }} />

                    <Box sx={{ flex: isTablet ? 'auto' : '1 0 50%', maxWidth: isTablet ? '100%' : '50%' }}>
                        <Typography variant="h5">Download Engagements</Typography>
                        <CSVDownload/>
                    </Box>

                </Box>

            </Container>

        </ThemeProvider>
    );
};

export default Dashboard;