import axios from 'axios';

export const authenticateUser = async (username, password) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true,
        });
        const response = await api.post('/api/authenticate', {username, password});
        if (response.data.message === 'Login successful') {
            return response.data;
        } else {
            throw new Error('Invalid username or password');
        }

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw error;
    }
};

export const logoutUser = async () => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true,
        });
        const response = await api.post('/api/logout');
        if (response.data.message === 'Logout successful') {
            return response.data;
        } else {
            throw new Error('Logout failed');
        }

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw error;
    }
};