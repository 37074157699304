import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {downloadDataForMigration} from '../../services/migrationService';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EngagementDropdown from "../Engagements/Engagements";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

const Migration = () => {
    const navigate = useNavigate();
    const [sourceKey, setSourceKey] = useState('');
    const [targetKey, setTargetKey] = useState('');
    const [engagement, setEngagement] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [migrationsLink, setMigrationsLink] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleMigration = async (e) => {
        e.preventDefault();
        setMessage(null);
        setMigrationsLink(false);
        setError(null);
        setEngagement('');

        if (!sourceKey || !targetKey || !engagement) {
            setError('All fields are required');
            return;
        }

        setIsLoading(true);
        try {
            const data = await downloadDataForMigration(sourceKey, targetKey, engagement);
            if (data.message) {
                setMessage(data.message);
            }
            if (data.checkDownload) {
                setMigrationsLink(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error.message === '500') {
                navigate('/500');
            }
            setError(error.message);
            setIsLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleMigration} noValidate sx={{mt: 1}}>

            <EngagementDropdown
                onEngagementChange={(value) => setEngagement(value)}
                value={engagement || ''}
                includeQuotes={false}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="sourceKey"
                label="HubSpot source account access token"
                name="sourceKey"
                autoFocus
                value={sourceKey}
                onChange={(e) => setSourceKey(e.target.value)}
            />

            <TextField
                margin="normal"
                required
                fullWidth
                id="targetKey"
                label="HubSpot target account access token"
                name="targetKey"
                value={targetKey}
                onChange={(e) => setTargetKey(e.target.value)}
            />

            {message && <Alert severity="info">{String(message)}</Alert>}

            {error && <Alert severity="error">{String(error)}</Alert>}

            {migrationsLink && <a href="/migrations">Click here to check status</a>}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24}/> : 'Download Data for migration'}
            </Button>

        </Box>
    );
};

export default Migration;