import React, {useState} from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const EngagementDropdown = ({onEngagementChange, value, includeQuotes = true}) => {

    const handleChange = (event) => {
        onEngagementChange(event.target.value);
    };

    return (
        <FormControl fullWidth>
            <InputLabel id="engagement">HubSpot Engagements</InputLabel>
            <Select
                labelId="engagement"
                id="engagement"
                value={value || ''}
                onChange={handleChange}
                label="HubSpot Engagements"
            >
                <MenuItem value={'calls'}>Calls</MenuItem>
                <MenuItem value={'communications'}>Communications</MenuItem>
                <MenuItem value={'emails'}>Emails</MenuItem>
                <MenuItem value={'meetings'}>Meetings</MenuItem>
                <MenuItem value={'notes'}>Notes</MenuItem>
                <MenuItem value={'postalMail'}>Postal Mail</MenuItem>
                <MenuItem value={'tasks'}>Tasks</MenuItem>
                {includeQuotes && <MenuItem value={'quotes'}>Quotes</MenuItem>}
            </Select>
        </FormControl>
    );
};

export default EngagementDropdown;