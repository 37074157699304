import axios from 'axios';
import fileDownload from "js-file-download";

export const getFiles = async (fetchId) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get(`/api/fetch-files/${fetchId}`);

        if (response.status !== 200) {
            throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw new Error(`Error fetching Files: ${error.message}`);
    }
};

export const downloadFile = async (fileId, fileName) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true,
            responseType: 'blob'
        });
        const response = await api.get(`/api/download-csv/${fileId}`);

        if (response.status !== 200) {
            throw new Error(`Request failed with status code ${response.status}`);
        }

        fileDownload(response.data, fileName);

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw new Error(`Error downloading file: ${error.message}`);
    }
};