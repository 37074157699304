const Logo = () => (
    <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="30" r="12" stroke="black" strokeWidth="2" fill="white" />
        <circle cx="45" cy="30" r="12" stroke="black" strokeWidth="2" fill="white" />
        <path d="M 21 30 L 39 30" stroke="black" strokeWidth="2" />
        <polygon points="39,30 33,24 33,36" fill="black"/>
        <text x="50%" y="54" textAnchor="middle" fill="black" dy=".3em" fontSize="8">
            HS Migrate
        </text>
    </svg>
);

export default Logo;