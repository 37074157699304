import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%!important',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: "#f5f5f5",
}));

const Heading = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const Subheading = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const ServerError = () => {
    return (
        <StyledContainer>
            <Heading variant="h1">
                500
            </Heading>
            <Subheading variant="h4">
                Server Error
            </Subheading>
            <Box>
                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                    There was a problem with our server. Please try again later.
                </Typography>
                <Button variant="contained" color="primary" href="/">
                    Go to Home
                </Button>
            </Box>
        </StyledContainer>
    );
};

export default ServerError;