import React, {useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchHubSpotData} from '../../services/csvService';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import EngagementDropdown from "../Engagements/Engagements";
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';

const CSVDownload = () => {
    const navigate = useNavigate();
    const [apiKey, setApiKey] = useState('');
    const [engagement, setEngagement] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState(null);
    const [downloadLink, setDownloadLink] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const clearForm = () => {
        setEngagement('');
        setError(null);
        setIsLoading(false);
    };

    const handleDownload = async (e) => {
        e.preventDefault();
        clearForm();
        setMessage(null);
        setDownloadLink(false);

        if (!apiKey || !engagement) {
            setError('Both engagement and API key are required');
            return;
        }
        setIsLoading(true);
        try {
            const data = await fetchHubSpotData(apiKey, engagement);
            if (data.message) {
                setMessage(data.message);
            }
            if (data.checkDownload) {
                setDownloadLink(true);
            }
            setIsLoading(false);
        } catch (error) {
            if (error.message === '500') {
                navigate('/500');
            }
            setError(error.message);
            setIsLoading(false);
        }
    };

    return (
        <Box component="form" onSubmit={handleDownload} noValidate sx={{mt: 1}}>
            <EngagementDropdown
                onEngagementChange={(value) => setEngagement(value)}
                value={engagement || ''}
            />
            <TextField
                margin="normal"
                required
                fullWidth
                id="apiKey"
                label="HubSpot Account Access token"
                name="apiKey"
                autoFocus
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
            />

            {message && <Alert severity="info">{String(message)}</Alert>}

            {error && <Alert severity="error">{String(error)}</Alert>}

            {downloadLink && <a href="/downloads">Click here to check download status</a>}

            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{mt: 3, mb: 2}}
                disabled={isLoading}
            >
                {isLoading ? <CircularProgress size={24}/> : 'Fetch Data from HubSpot'}
            </Button>
        </Box>
    );
};

export default CSVDownload;