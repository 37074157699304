import React, {useContext, useEffect} from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import {getUserInfo} from "../../services/userService";

const PrivateRoute = ({path, element: Component, ...rest}) => {
    const {isAuthenticated, logout} = useContext(AuthContext);

    useEffect(() => {
        getUserInfo().catch((err) => {
            if (err.response && err.response.status === 401) {
                logout();
            } else {
                console.error(err);
            }
        });
    }, [])

    return isAuthenticated ? <Outlet/> : <Navigate to="/login" replace/>;
};

export default PrivateRoute;