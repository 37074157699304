import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {fetchAllDownloads, generateCSVFiles, deleteDownload} from "../../services/csvService";
import {downloadFile, getFiles} from "../../services/fileService";
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppHeader from "../AppHeader/AppHeader";
import Box from "@mui/material/Box";
import DeleteIcon from '@mui/icons-material/Delete';
import {margin} from "@mui/system";

const defaultTheme = createTheme();

const Downloads = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [downloads, setDownloads] = useState([]);
    const [files, setFiles] = useState([]);
    const [isGenerating, setIsGenerating] = useState({});
    const [isDownloading, setIsDownloading] = useState(false);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const data = await fetchAllDownloads();
                if (data.length === 0) {
                    setMessage('No downloads available');
                } else {
                    setDownloads(data);
                    const filesData = await Promise.all(data.map(download => getFiles(download.fetchId)));
                    setFiles(filesData.flat());
                    console.log(filesData.flat());
                }
            } catch (error) {
                if (error.message === '500') {
                    navigate('/500');
                }
                setError(`Error fetching downloads: ${error.message}`);
            }
        })();
    }, []);

    const clearForm = () => {
        setIsGenerating(false);
        setMessage(null);
        setError(null);
    }

    const handleCSVFileGeneration = async (fetchID) => {
        setIsGenerating(prevState => ({...prevState, [fetchID]: true}));
        setMessage('Data is processing for download...');
        try {
            const data = await generateCSVFiles(fetchID);
            setIsGenerating(prevState => ({...prevState, [fetchID]: false}));
            setMessage('Files are being generated. They will be available for download shortly.');
        } catch (error) {
            if (error.message === '500') {
                navigate('/500');
            }
            setError(`Error generating CSV file: ${error.message}`);
        } finally {
            clearForm();
            setMessage('File generating completed successfully');
            // sett message to null after 2 seconds
            setTimeout(() => {
                setMessage(null);
            }, 2000);
        }
    };

    const handleFileDownload = async (fileId, fileName) => {
        setIsDownloading(true);
        try {
            await downloadFile(fileId, fileName);
        } catch (error) {
            console.error(`Error downloading file: ${error.message}`);
        } finally {
            setIsDownloading(false);
        }
    };

    const handleDelete = async (fetchID) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this download?');
        if (confirmDelete) {
            try {
                const data = await deleteDownload(fetchID);
                setDownloads(downloads.filter(download => download.fetchId !== fetchID));
                if (downloads.length < 1) {
                    setMessage('No downloads available');
                }
                setMessage('Download deleted successfully')
                // sett message to null after 2 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 2000);
            } catch (error) {
                if (error.message === '500') {
                    navigate('/500');
                }
                setError(`Error deleting download: ${error.message}`);
            }
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>

            <AppHeader/>

            <Container component="main" maxWidth="lg">

                <CssBaseline/>

                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                    }}
                >
                    {message && <Alert severity="info">{String(message)}</Alert>}
                    {error && <Alert severity="error">{String(error)}</Alert>}
                    <TableContainer
                        component={Paper}
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Portal ID</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Object</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Files Ready for Download</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {downloads.map((download) => (
                                    <TableRow key={download._id}>
                                        <TableCell>{download.portalId}</TableCell>
                                        <TableCell>{new Date(download.createdAt).toLocaleDateString()}</TableCell>
                                        <TableCell>{download.object}</TableCell>
                                        <TableCell>{download.status}</TableCell>
                                        <TableCell>
                                            {files.filter(file => file.fetchId === download.fetchId).map(file => (
                                                <div key={file._id}>
                                                    <a href="#" onClick={() => handleFileDownload(file._id, file.name)}>
                                                        {file.name}
                                                    </a>
                                                </div>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            <>
                                                {download.status === 'ready' && (
                                                    <Button variant="contained" color="primary"
                                                            sx={{marginRight: 2, color: 'white'}}
                                                            onClick={() => handleCSVFileGeneration(download.fetchId)}>
                                                        {isGenerating[download.fetchId] ?
                                                            <CircularProgress size={24}/> : 'Generate Files'}
                                                    </Button>
                                                )}
                                                <IconButton color="secondary" aria-label="delete"
                                                            onClick={() => handleDelete(download.fetchId)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    {isDownloading
                        ? (
                            <Alert severity="info" sx={{marginTop: 2}} icon={false}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                    <CircularProgress size={20} sx={{ marginRight: 2 }}/>
                                    <p style={{ margin: 0 }}>File is being downloaded...</p>
                                </div>
                            </Alert>
                        )
                        : (
                            <Alert severity="info" sx={{marginTop: 2}}>
                                Click on the file name to download the file
                            </Alert>
                        )
                    }
                </Box>

            </Container>
        </ThemeProvider>
    );
};
export default Downloads;