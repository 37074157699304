import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from "@mui/material/Box";
import Divider from '@mui/material/Divider';
import Logo from '../Logo/Logo';
import AuthContext from '../../context/AuthContext';

const AppHeader = () => {
    const { user, logout } = useContext(AuthContext);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleLogout = () => {
        logout();
    };

    const getUserFullName = (user) => {
        const firstName = user.name.first.charAt(0).toUpperCase() + user.name.first.slice(1);
        const lastName = user.name.last.charAt(0).toUpperCase() + user.name.last.slice(1);
        return `${firstName} ${lastName}`
    }

    return (
        <AppBar position="static">
            <Toolbar sx={{
                flexDirection: isMobile ? 'column' : 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                alignItems: 'center'
            }}>
                <Link to="/">
                    <IconButton edge="start" color="inherit" aria-label="logo">
                        <Logo/>
                    </IconButton>
                </Link>
                <Box sx={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    padding: isMobile ? '0 0 20px' : '0px'
                }}>
                    <Typography variant="subtitle1">
                        Logged in as: {getUserFullName(user)}
                    </Typography>
                    <Divider orientation="vertical" variant="middle" flexItem sx={{ mx: 3 }} />
                    <Button color="inherit"
                            onClick={handleLogout}
                            variant="outlined"
                    >
                        Logout
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default AppHeader;