import axios from 'axios';

export const getUserInfo = async () => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true,
        });
        const response = await api.get('/api/user-info');
        if (!response.data) {
            throw new Error('User not found');
        }
        return response.data;

    } catch (error) {
        if (error.response.status === 500) {
            throw new Error('500');
        }
        throw error;
    }
};