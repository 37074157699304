import axios from 'axios';

export const getAllMigrations = async () => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get('/api/fetch-all-migrations');

        if (response.status !== 200) {
            throw new Error(`Request failed with status code: ${response.status}`);
        }

        return response.data;

    } catch (error) {
        throw new Error(`Error fetching migrations: ${error.message}`);
    }
};

export const deleteMigration = async (fetchId) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.delete(`/api/delete-migration/${fetchId}`);

        if (response.status !== 200) {
            throw new Error(`Request failed with status code: ${response.status}`);
        }

        return response.data;

    } catch (error) {
        throw new Error(`Error deleting migration: ${error.message}`);
    }
};

export const downloadDataForMigration = async (sourceKey, targetKey, engagement) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get(`/api/fetch-hubspot-data/${engagement}`, {
            headers: {
                'Cache-Control': 'no-cache, must-revalidate',
                'x--source-api-key': sourceKey,
                'x--target-api-key': targetKey
            }
        });

        if (response.status !== 200 && response.status !== 202) {
            throw new Error(`Request failed with status code: ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw new Error(`Error fetching data from HubSpot: ${error.message}`);
    }
};

export const migrateData = async (fetchId) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get(`/api/migrate-hubspot-data/${fetchId}`);

        if (response.status !== 200 && response.status !== 202) {
            throw new Error(`Request failed with status code: ${response.status}`);
        }

        return response.data;

    } catch (error) {
        throw new Error(`Error migrating data: ${error.message}`);
    }
};