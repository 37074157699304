import React, {useState, useContext, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {authenticateUser} from '../../services/authService';
import {getUserInfo} from '../../services/userService';
import AuthContext from '../../context/AuthContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import {createTheme, ThemeProvider} from '@mui/material/styles';

function Copyright(props) {
    const absoluteUrl = window.location.href;
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} sx={{ position: 'fixed', left: 0, bottom: 0, paddingY: '20px', width: '100%', backgroundColor: '#fff', textAlign: 'center' }}>
            {'Copyright © '}
            <Link color="inherit" href={absoluteUrl}>
                HS Migrate
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const defaultTheme = createTheme();

const Login = () => {
    const {login, user} = useContext(AuthContext);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate('/');
        }
    }, [user, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();

        if (username.trim() === '' || password.trim() === '') {
            setErrorMessage('Both fields are required');
            return;
        }

        setIsLoading(true);
        try {
            const response = await authenticateUser(username, password);
            const userInfo = await getUserInfo();
            login(userInfo);
            navigate('/');
        } catch (error) {
            if (error.message === '500') {
                navigate('/500');
            }
            if (error.response && error.response.data) {
                setErrorMessage(error.response.data.message);
            } else {
                setErrorMessage('An error occurred');
            }
            setIsLoading(false);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>

            <Container component="main" maxWidth="xs">

                <CssBaseline/>

                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >

                    <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
                        <LockOutlinedIcon/>
                    </Avatar>

                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>

                    <Box component="form" onSubmit={handleLogin} noValidate sx={{mt: 1}}>

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="Username"
                            name="username"
                            autoFocus
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                        />

                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />

                        {errorMessage && (
                            <Alert severity="error">
                                {errorMessage}
                            </Alert>
                        )}

                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{mt: 3, mb: 2}}
                            disabled={isLoading}
                        >
                            {isLoading ? <CircularProgress size={24} /> : 'Sign In'}
                        </Button>

                    </Box>

                </Box>

                <Copyright sx={{mt: 8, mb: 4}}/>

            </Container>

        </ThemeProvider>
    );
};

export default Login;