import React from 'react';
import {Container, Box, Typography, Button} from '@mui/material';
import {styled} from '@mui/system';

const StyledContainer = styled(Container)(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '100%!important',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: "#f5f5f5",
}));

const Heading = styled(Typography)(({theme}) => ({
    marginBottom: theme.spacing(2),
}));

const Subheading = styled(Typography)(({theme}) => ({
    marginBottom: theme.spacing(4),
}));

const NotFound = () => {
    return (
        <StyledContainer>
            <Heading variant="h1">
                404
            </Heading>
            <Subheading variant="h4">
                Page Not Found
            </Subheading>
            <Box>
                <Typography variant="body1" sx={{marginBottom: 2}}>
                    The page you are looking for does not exist.
                </Typography>
                <Button variant="contained" color="primary" href="/">
                    Go to Home
                </Button>
            </Box>
        </StyledContainer>
    );
};

export default NotFound;