import axios from 'axios';

export const fetchHubSpotData = async (apiKey, engagement) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get(`/api/fetch-hubspot-data/${engagement}`, {
            headers: {
                'Cache-Control': 'no-cache, must-revalidate',
                'x-api-key': apiKey
            }
        });

        if (response.status !== 200 && response.status !== 202) {
            throw new Error(`Request failed with status code: ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        console.log(error)
        throw new Error(`Error fetching data from HubSpot: ${error.message}`);
    }
};

export const generateCSVFiles = async (fetchId) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true,
            responseType: 'blob'
        });
        const response = await api.get(`/api/generate-csv/${fetchId}`);

        if (response.status !== 200) {
            throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw new Error(`Error generating CSV files: ${error.message}`);
    }
};

export const fetchAllDownloads = async () => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.get('/api/fetch-all-downloads');

        if (response.status !== 200) {
            throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }

        console.error(`Error Fetching all downloads: ${error}`);

        throw error;
    }
};

export const deleteDownload = async (fetchId) => {
    try {
        const api = axios.create({
            baseURL: process.env.REACT_APP_SERVER_URL,
            withCredentials: true
        });
        const response = await api.delete(`/api/delete-download/${fetchId}`);

        if (response.status !== 200) {
            throw new Error(`Request failed with status code ${response.status}`);
        }

        return response.data;

    } catch (error) {
        if (error.response && error.response.status === 500) {
            throw new Error('500');
        }
        throw new Error(`Error deleting download: ${error.message}`);
    }
};