import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {getAllMigrations, migrateData, deleteMigration} from "../../services/migrationService";
import {createTheme, ThemeProvider, useTheme} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import useMediaQuery from "@mui/material/useMediaQuery";
import AppHeader from "../AppHeader/AppHeader";
import Box from "@mui/material/Box";
import DeleteIcon from '@mui/icons-material/Delete';

const defaultTheme = createTheme();

const MigrationsList = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [migrations, setMigrations] = useState([]);
    const [isMigrating, setIsMigrating] = useState({});
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    useEffect(() => {
        (async () => {
            try {
                const data = await getAllMigrations();
                if (data.length === 0) {
                    setMessage('No data available');
                } else {
                    setMigrations(data);
                }
            } catch (error) {
                if (error.message === '500') {
                    navigate('/500');
                }
                setError(`Error fetching migration: ${error.message}`);
            }
        })();
    }, []);

    const handleMigration = async (fetchID) => {
        setIsMigrating(prevState => ({ ...prevState, [fetchID]: true }));
        setMessage('Data is bing migrated...');
        try {
            const data = await migrateData(fetchID);
            if (data.message) {
                setMessage(data.message);
            }
        } catch (error) {
            if (error.message === '500') {
                navigate('/500');
            }
            setError(`Error migrating data: ${error.message}`);
        }
    };

    const handleDelete = async (fetchID) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this download?');
        if (confirmDelete) {
            try {
                const data = await deleteMigration(fetchID);
                setMigrations(migrations.filter(migration => migration.fetchId !== fetchID));
                if (migrations.length < 1) {
                    setMessage('No migrations available');
                }
                setMessage('Migration deleted successfully')
                // sett message to null after 2 seconds
                setTimeout(() => {
                    setMessage(null);
                }, 2000);
            } catch (error) {
                if (error.message === '500') {
                    navigate('/500');
                }
                setError(`Error deleting migration: ${error.message}`);
            }
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>

            <AppHeader/>

            <Container component="main" maxWidth="lg">

                <CssBaseline/>

                <Box
                    sx={{
                        marginTop: 8,
                        marginBottom: 8,
                    }}
                >
                    {message && <Alert severity="info">{String(message)}</Alert>}
                    {error && <Alert severity="error">{String(error)}</Alert>}

                    <TableContainer
                        component={Paper}
                        sx={{
                            marginTop: 2
                        }}
                    >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Fetch ID</TableCell>
                                    <TableCell>Source Portal ID</TableCell>
                                    <TableCell>Target Portal ID</TableCell>
                                    <TableCell>Created Date</TableCell>
                                    <TableCell>Object</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {migrations.map((migration) => (
                                    <TableRow key={migration._id}>
                                        <TableCell>{migration.fetchId}</TableCell>
                                        <TableCell>{migration.sourcePortalId}</TableCell>
                                        <TableCell>{migration.targetPortalId}</TableCell>
                                        <TableCell>{new Date(migration.createdAt).toLocaleDateString()}</TableCell>
                                        <TableCell>{migration.object}</TableCell>
                                        <TableCell>{migration.status}</TableCell>
                                        <TableCell>
                                            <>
                                                {(migration.status === 'ready' || migration.status === 'failed') && (
                                                    <Button variant="contained" color="primary"
                                                            sx={{marginRight: 2, color: 'white'}}
                                                            onClick={() => handleMigration(migration.fetchId)}>
                                                        {isMigrating[migration.fetchId] ? <CircularProgress size={24} color="inherit"/> : 'Migrate Data'}
                                                    </Button>
                                                )}
                                                <IconButton color="secondary" aria-label="delete"
                                                            onClick={() => handleDelete(migration.fetchId)}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

            </Container>
        </ThemeProvider>
    );
};
export default MigrationsList;