import React, {useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Downloads from "./components/Downloads/Downloads";
import MigrationsList from "./components/Migration/MigrationsList";
import {AuthProvider} from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import NotFound from "./components/ErrorPages/NotFound";
import ServerError from "./components/ErrorPages/ServerError";

const App = () => {
    const [isAuthenticated, setAuthenticated] = useState(false);

    const login = () => {
        setAuthenticated(true);
    };

    const logout = () => {
        setAuthenticated(false);
    };

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    <Route exact path="/login" element={<Login/>}/>
                    <Route exact path="/" element={<PrivateRoute/>}>
                        <Route exact path="/" element={<Dashboard/>}/>
                        <Route exact path="/downloads" element={<Downloads/>}/>
                        <Route exact path="/migrations" element={<MigrationsList/>}/>
                    </Route>
                    <Route path="/500" element={<ServerError/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </AuthProvider>
        </Router>
    );
};

export default App;